import { Context as C, createContext, useContext } from "react"

let initialState = { item: [], dispatch: () => { } }

const Context = createContext(initialState)



export const useItemContext = () => useContext(Context);

export default Context

