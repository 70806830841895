export function reducer(state, action) {
  switch (action.type) {
    case "add":
      return {
        ...state,
        item: [...state.item, action.payload],
      };
    case "remove":
      return {
        ...state,
        item: Object.values(state.item).filter((item) => item.id != action.payload.id),
      };
    default:
      return state;
  }
}
